import React, { Component } from "react"
import { graphql } from "gatsby"

import { GeoInfo } from "../components/WebMapView/"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import MyForest from "../components/WebMapView/myForest"
import { AllCo2XlsxAnnually } from "../components/WebMapView/treeBondingChart"

export interface ThirdPageProps {
  data: AllCo2XlsxAnnually
}
export interface ThirdPageState {}

export const pageQuery = graphql`
  {
    allCo2XlsxAnnually {
      edges {
        node {
          Po0
          Po2
          Po_2
          SS0
          SS2_4
          SS6_10
          Lp0
          Lp2
          Lp4
          RL4
          RL8
          RL12
          RL16
          IB5
          IB8
          age
        }
      }
    }
  }
`
const geoinfo: GeoInfo = {
  groska: { AO: "Po2", SG: "SS6_10", SF: "Lp4", RL: "RL16", IB: "IB8" },
  groskaMin: {},
  skogsvaedi: {},
  svaedi: { ID1: "", ID2: "", ID3: "", ID4: 0 },
}

class ThirdPage extends Component<ThirdPageProps, ThirdPageState> {
  constructor(props: ThirdPageProps) {
    super(props)
  }

  render() {
    //console.log("Annual carbon data: ", this.props.data)
    return (
      <Layout>
        <SEO title="Page three" />
        {typeof this.props.data !== "undefined" && (
          <MyForest data={this.props.data} geoinfo={geoinfo} />
        )}
      </Layout>
    )
  }
}

export default ThirdPage
